<template>
  <v-app :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <AppBar
      color="primary"
      app-name="mma"
      title="Manage My Access"
      :show-full-screen="false"
      :show-nav-icon="true"
      :extended="$vuetify.breakpoint.smAndDown"
    >
      <template
        #appIcon
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <AppIcon
          app-name="my-mma"
          :transparent="true"
          size="50px"
          alt="MMA logo"
          class="ml-3 mt-2"
        />
      </template>

      <template :slot="$vuetify.breakpoint.mdAndUp ? 'dataSwitcher' : 'extension'">
        <v-container
          id="app-bar--location-container"
          style="min-width: 320px"
        >
          <div id="schoolPicker">
            <label
              id="app-bar--select-schoool-label"
              class="visuallyhidden"
            >
              Your Schools
            </label>
            <v-autocomplete
              v-model="localSelectedSchool"
              return-object
              :search-input.sync="schoolFilterText"
              :items="schoolsFiltered"
              item-text="name"
              item-value="id"
              :filter="filterSchool"
              clearable
              solo
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search for a school..."
              aria-labelledby="app-bar--select-schoool-label"
              ref="schoolPicker"
              :menu-props="{'contentClass': 'school-picker-menu'}"
              v-bind="{
                'flat': $vuetify.breakpoint.smAndDown,
                'full-width': $vuetify.breakpoint.smAndDown,
                'background-color': $vuetify.breakpoint.smAndDown ? '#041E42' : null
              }"
              @change="schoolSelected"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="{'subtitle-2': data.item.header != null, 'ads-light-40': data.item.header != null}"
                    >
                      {{ data.item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
              <template v-slot:append-item>
                <div
                  id="other-schools--container"
                  v-if="suffixInstructions"
                >
                  <v-list>
                    <v-list-item
                      @click="$refs.schoolPicker.focus()"
                      :aria-label="suffixInstructions"
                    >
                      <div
                        class="subtitle-2 ads-light-40 font-italic"
                      >
                        {{ suffixInstructions }}
                      </div>
                    </v-list-item>
                  </v-list>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </v-container>
      </template>

      <template #profile>
        <Profile
          :given-name="profile.given_name || profile.givenName"
          :surname="profile.sn"
          :job-title="jobTitle"
          :update-profile="false"
          :change-password="false"
          :update-secret-question="false"
          :portal-settings="false"
          :logout="true"
          :logout-handler="logout"
        />
      </template>
    </AppBar>

    <NavBar />

    <v-main>
      <v-container
        fluid
        class="pa-0"
      >
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <!-- app wide components -->
    <BlockUI v-if="isLoading" />
    <Dialog
      :title="`${errorTitle} ${errorCode ? '(' + errorCode + ')' : ''}`"
      type="error"
      actions="Ok"
      ref="genericError"
      :value="showErrorDialog"
      :callback="errorCallback"
      :message="errorMessage"
    />
    <Alert
      :show-alert="snackbarProps.show"
      :type="snackbarProps.type"
      allow-dismiss
      :text="snackbarProps.text"
      subtext="Optional text here."
    />
  </v-app>
</template>

<script>
  import { AppBar, Profile, AppIcon, BlockUI, Alert } from "@nswdoe/doe-ui-core";
  import NavBar from '@/components/NavBar.vue';
  import Dialog from "@/components/Dialog";
  import { getConfig } from '@/utils/config';
  import locationsApi from 'api/schools';
  import { mapGetters } from "vuex";

  export default {
    name: "App",
    components: {
      BlockUI,
      AppBar,
      Profile,
      NavBar,
      AppIcon,
      Dialog,
      Alert
    },
    data() {
      return {
        schoolFilterText: "",
        localSelectedSchool: null,
        selectedOtherSchool: {},
        menuOpened: false
      }
    },
    created() {
      const googleAnalyticsId = getConfig('VUE_APP_GOOGLE_ANALYTICS_ID');
      if (googleAnalyticsId && googleAnalyticsId !== '') {
        let googleAnalyticsSrc = document.createElement('script');
        googleAnalyticsSrc.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`);
        document.body.appendChild(googleAnalyticsSrc);

        window.dataLayer = window.dataLayer || [];
        const gtag = function() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', googleAnalyticsId);
      }
    },
    mounted() {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        // this.$vuetify.theme.dark = true;
      }
    },
    computed: {
      ...mapGetters({
        error: "error",
        isLoading: 'isLoading',
        snackbarProps: "snackbar",
        userSchools: "userSchools",
        otherSchools: "otherSchools",
        pendingSchools: "pendingSchools",
        fnActionByName: 'getActionByName',
        selectedSchoolId: "selectedSchoolId",
      }),
      profile() {
        return this.$OAuth.data.profile || {};
      },
      oauthLoaded() {
        return this.$OAuth.loaded;
      },
      jobTitle() {
        const roles = this.profile.roles || this.profile.detroles;
        if (roles && roles.length) {
          if (this.isPrincipal(roles)) {
            return "Principal";
          }

          if (this.isTeacher(roles)) {
            return "Teacher";
          }

          if (this.isCorporate(roles)) {
            return "Other Corporate";
          }

          if (this.isContractor(roles)) {
            return "Contractor";
          }
        }
        return "Staff";
      },
      showErrorDialog() { return this.error.show; },
      errorTitle() { return this.error.title; },
      errorCode() { return this.error.code; },
      errorMessage() { return this.error.message; },
      schoolsFiltered() {
        const schools = [...this.userSchools];

        // add pending schools into the main schools list
        for (const ps of this.pendingSchools) {
          if (!schools.some(s => s.id === ps.id)) {
            schools.push(Object.assign({}, ps, {name: ps.name + " (pending)"}));
          }
        }

        const schoolsList = schools;

        // show "My schools" header if the filtered list will have at least one school
        if (schoolsList.filter(s => this.filterSchool(s, (this.schoolFilterText || ""), s.name)).length) {
          schoolsList.unshift({
            header: 'My schools',
            isHeader: true
          });
        }

        if (this.selectedOtherSchool && this.selectedOtherSchool.id != null) {
          // add selected "other" school into the schools list
          schoolsList.push(
            Object.assign({},
              this.selectedOtherSchool,
              // if the school is re-selected, don't append pending again.
              {name: this.selectedOtherSchool.name  + (this.selectedOtherSchool.name.indexOf(" (pending)") === -1 ? " (pending)" : "")}
            )
          );
        }

        // Show Other schools only when performing a search because there could be thousands of them
        if (this.otherSchoolsFiltered.length) {
          if (schoolsList.length) {
            schoolsList.push({ divider: true });
          }
          const otherSchoolsTitle = {
            header: 'Other schools',
            isHeader: true
          };
          schoolsList.push(otherSchoolsTitle);
          schoolsList.push(...this.otherSchoolsFiltered.map(s => Object.assign({}, s, {isOtherSchool: true})));
        }

        return schoolsList;
      },
      suffixInstructions() {
        if (!this.schoolFilterText || (this.localSelectedSchool && this.schoolFilterText.indexOf(this.localSelectedSchool.name) === 0)) {
          // selected or empty state
          return "Enter a school name to search for other schools";
        }

        if (this.schoolFilterText && this.schoolFilterText.length >= 2 && !this.otherSchoolsFiltered.length) {
          // no other schools found
          return "No other schools found for \"" + this.schoolFilterText + "\"";
        }

        // other schools found
        return "";
      },
      otherSchoolsFiltered() {
        const otherSchools = this.otherSchools;
        if (this.schoolFilterText && this.schoolFilterText.length >= 2) {
          const schoolIds = this.userSchools.map(s => s.id);
          return otherSchools
            .filter(s =>
              (s.name.toLowerCase() || "").indexOf(this.schoolFilterText.toLowerCase()) !== -1
              && schoolIds.indexOf(s.id) === -1)
            .map(s => Object.assign({}, s, {isOtherSchool: true}));
        }
        return [];
      }
    },
    watch: {
      oauthLoaded: {
        immediate: true,
        handler(loaded) {
          // kick of load of school locations after authentication is completed
          if (loaded) {
            if (!this.userSchools.length && !this.otherSchools.length) {
              this.$store.dispatch("fetchSchools")
              .then(() => {
                // watch on selectedSchoolId will trigger the school select if the reset changes the school
                this.$store.dispatch("resetToDefaultSchool");
              });
            }
            if (!this.$store.getters.requestHistory.length) {
              this.$store.dispatch("fetchHistory");
            }
          }
        }
      },
      selectedSchoolId(newVal) {
        // watch for programmatic changes to selected school
        if (!this.localSelectedSchool || (this.localSelectedSchool && newVal !== this.localSelectedSchool.id)) {
          const selectedSchool = this.schoolsFiltered.find(s => s.id === newVal);
          this.schoolSelected(selectedSchool);
          this.selectedOtherSchool = {};
        }
      }
    },
    methods: {
      filterSchool(item, query, itemText) {
        return item.header || itemText.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      },
      schoolSelected(school) {
        if (school && school.isOtherSchool) {
          this.otherSchoolSelected(school);
          return;
        }

        const schoolId = school ? school.id : null;
        this.$store.commit("setSelectedSchool", schoolId);

        if (schoolId != null) {
          localStorage.setItem("defaultSchoolId", schoolId);
          this.localSelectedSchool = school;

          // show the apps for the school
          if (this.$router.currentRoute.path !== '/access') {
            this.$router.push("/access");
          }
        } else {
          // go home
          if (this.$router.currentRoute.path !== '/') {
            this.$router.push("/");
          }
        }
      },
      otherSchoolSelected(school) {
        // other school gets added to the schools list
        this.selectedOtherSchool = school;

        // set the other school as the value so it shows in the autocomplete
        this.$refs.schoolPicker.setValue(school);

        this.$refs.schoolPicker.isMenuActive = false;

        this.$store.commit("setSelectedSchool", school.id);
        if (this.$router.currentRoute.path !== '/roles') {
          this.$router.push("/roles");
        }
      },
      errorCallback: function() {
        if (typeof this.$store.getters.error.callback === 'function') {
          this.$store.getters.error.callback();
        }
        this.$store.commit('setError', {show: false});
      },
      logout() {
        locationsApi.clearPermissionsCache();
        this.$OAuth.logout();
        return false;
      },
      isPrincipal(roles) {
        const target = "SCHOOL.PRINCIPAL";
        return this.isTarget(roles, target);
      },
      isTeacher(roles) {
        const target = "SCHOOL.TEACHER";
        return this.isTarget(roles, target);
      },
      isCorporate(roles) {
        const target = "SCHOOL.OTHERCORPORATE";
        return this.isTarget(roles, target);
      },
      isContractor(roles) {
        const target = "DET.CONTRACTOR";
        return this.isTarget(roles, target);
      },
      isTarget(roles, target) {
        return roles.indexOf(target) !== -1 || roles === target;
      }
    }
  };
</script>

<style scoped>
  #app.mobile #app-bar--location-container {
    padding-left: 0;
    padding-right: 0;
  }
  #app-bar--location-container .v-input__control .v-input__slot {
    overflow: hidden;
    background-color: #1A3B69;
  }
</style>

<style lang="scss">
@import './scss/ads.scss';

#app.mobile {
  .v-application--wrap, main.v-main .v-main__wrap {
    background-color: #FFFFFF;
  }

  #schoolPicker {
    min-width: 0;
  }
  .school-picker-menu.v-menu__content {
    min-width: 100vw !important;
    left: 0 !important;
  }
}

#schoolPicker {
  min-width: 380px;
  max-width: 100vw;
  .v-input__slot {
    margin-bottom: 0;
    background: $ads-navy-light-1;
  }
}

#other-schools--container .v-input {
  .v-input__slot {
    margin-bottom: 0;
  }
}

// smooth scroll for otherSchoolFilter auto scroll
.v-application > .v-menu__content.v-menu__content--fixed {
  scroll-behavior: smooth;
}

// search list text box styling
div.data-table--search-field {
  fieldset {
    border-top: 0;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #E5E5E5;
  }
  div.v-input__prepend-inner {
    margin-top: 0;
    align-self: center;
  }
  .v-input__slot {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

  // highlight focused elements for accessibility
  .v-btn:focus,
  .v-expansion-panel-header:focus,
  .v-text-field--solo.v-input--is-focused .v-input__slot {
    border: 2px solid rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for other browsers */
  }

  .theme--dark {
    .v-btn:focus,
    .v-expansion-panel-header:focus,
    .v-text-field--solo.v-input--is-focused .v-input__slot {
      border: 2px solid rgba(255, 255, 255, 0.7);
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for other browsers */
    }
  }
</style>
