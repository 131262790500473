<template>
  <NavigationDrawer :items="menuItems">
    <template v-slot:postMenu>
      <div class="post-menu">
        <div class="nav-footer">
          <img
            class="logo__image"
            src="../../public/assets/nsw-footer-logo.svg"
            alt="Doe logo"
          >
        </div>
        <a
          title="Manage My Access User Guide"
          target="_blank"
          class="ads-white"
          rel="noopener noreferrer"
          download="Manage_My_Access_MMA_User_Guide.pdf"
          href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/technology/media/documents/Manage_My_Access_MMA_User_Guide.pdf"
        >User guide</a>
      </div>
    </template>
  </NavigationDrawer>
</template>

<script>
import {NavigationDrawer} from '@nswdoe/doe-ui-core';

export default {
  name: "NavBar",
  components: {
    NavigationDrawer
  },
  data() {
    return {
      menuItems: [{
          id: 'nav-bar--my-access',
          title: 'My Access',
          icon: 'mdi-account-multiple-outline',
          route: '/access'
        }, {
          id: 'nav-bar--history',
          title: 'History',
          icon: 'mdi-calendar-blank-outline',
          route: '/history'
        }]
    }
  }
};
</script>

<style scoped>
</style>
